import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import '../assets/css/style.css';
import Header from '../components/Header';
import BalanceAndStats from '../components/home/Balnce';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import MenuModal from '../components/quickmenu';
import ConnectionSwiper from '../components/home/coins';
import { postHelper, priceHelper } from '../helpers/apiHelper';
import { fetchData } from '../helpers/security';
import { useNavigate } from 'react-router-dom';
const FinwallApp = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menu = useSelector((state) => state.menu)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // console.log(menu.userData);
    const token = fetchData('user');

    const fetch = async () => {
      const res = await postHelper('user/userdata', {}, token);
      if (res.type === "auth") { 
        navigate('/sessionexpired');
      } 
      if (res.error) {
        
      }else{
        // console.log(res);
        const prices = await priceHelper();
        // console.log(prices);
        dispatch({ type: 'PRICES', payload: prices });
        dispatch({ type: 'USERDATA', payload: res });
      }
    }

    if (!token) {
      navigate('/signin');
    }else{
      fetch();
    }

  }, [])
  const toggleMenu = () => {
    setIsMenuOpen(prevState => !prevState);
  };

  return (
          <>
          <BalanceAndStats />
          <ConnectionSwiper />
          </>
  );
};

export default FinwallApp;
